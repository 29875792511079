const topicSlug = {
  dane: 'dane/',
  mzdy: 'mzdy/',
  zahranici: 'zahranici/',
  firemniPoradenstvi: 'firemni-poradenstvi/',
  ucetnictvi: 'ucetnictvi/',
  znaleckePosudky: 'znalecke-posudky/',
  esg: 'esg/'
}

export const Index = {
  dane: {
    title: 'Daně',
    slug: topicSlug.dane,
    description: `Potřebujete zkonzultovat daňový dopad plánované transakce, podat
    přiznání či kontrolní hlášení, zastoupit na finančním úřadě nebo řešíte
    jiný problém spojený s daněmi? Obraťte se na nás- poradíme s přímými i
    nepřímými daněmi. Poskytujeme také rychlé konzultace po telefonu.
    Samozřejmostí je pro nás i analýza převodních cen.`,
    links: [
      {
        name: 'Daňové poradenství',
        slug: 'danove-poradenstvi/',
        description:
          'Jsme tým certifikovaných daňových poradců a veškeré změny v legislativě, judikatuře či v přístupu finanční správy sledujeme za Vás.'
      },
      {
        name: 'Daňové přiznání',
        slug: 'danove-priznani/',
        description:
          'Obrátit se na nás můžete nejen pro kompletní zpracování daňových přiznání, ale také pro jednorázovou konzultaci.'
      },
      {
        name: 'DPPO',
        slug: 'dppo/',
        description:
          'Daň z příjmů právnických osob představuje jednu z nejvíce sledovaných daní a mnohdy bývá předmětem kontroly ze strany finančního úřadu. Buďte s námi o krok napřed a mějte daňové přiznání v pořádku.'
      },
      {
        name: 'Daňová kontrola',
        slug: 'danova-kontrola/',
        description:
          'Při jednání s finančním úřadem či jiným správcem daně postupujeme maximálně profesionálně a vycházíme jak ze zkušeností, tak z detailní znalosti zákona.'
      },
      {
        name: 'DPH',
        slug: 'dph/',
        description:
          'Nabízíme spolupráci s daňovými poradci, kteří se specializují právě na problematiku daně z přidané hodnoty a rádi Vám pomůžeme jak rychlou konzultací, tak převzetím celé DPH agendy.'
      },
      {
        name: 'Spotřební daně',
        slug: 'spotrebni-dane/',
        description:
          'Spotřební daně upravuje poměrně složitá legislativa, která se navíc neustále vyvíjí. Pokud si nejste jistí, zda byste měli být plátcem, chcete si ověřit sazbu daně nebo pomoci s výpočtem, jsme tu pro Vás.'
      },
      {
        name: 'Převodní ceny',
        slug: 'prevodni-ceny/',
        description:
          'Vypracujeme pro Vás analýzu tak, abyste splnili pravidlo tržního odstupu a zároveň nastavili transakce efektivně. Dále nabízíme pomoc v případě daňové kontroly nebo sporu.'
      }
    ]
  },
  mzdy: {
    title: 'Mzdy',
    slug: topicSlug.mzdy,
    description: `Vedení personální agendy je nejen administrativně nesmírně náročné, ale nese v sobě riziko, že se mezi zaměstnanci roznese výše platů – a i z tohoto důvodu se vyplácí mzdové účetnictví a evidenci outsourcovat. Mzdovou agendu vedeme maximálně diskrétně, spolehlivě a dodržujeme termíny. Stále sledujeme vývoj legislativy, abyste měli jistotu, že mzdy zpracováváme dle nejnovějších předpisů.`,
    links: [
      {
        name: 'Mzdové účetnictví',
        slug: 'mzdove-ucetnictvi/',
        description:
          'Stále sledujeme vývoj legislativy a pomůžeme i konzultacemi, například při nastavování náhrad mezd nebo evidenci pracovní doby. Nabízíme také zpracování celého finančního účetnictví.'
      },
      {
        name: 'Mzdová evidence',
        slug: 'mzdova-evidence/',
        description:
          'Ulevte firmě od nadbytečné administrativní zátěže. V rámci zpracování mzdového účetnictví nabízíme také vedení mzdové a personální evidence.'
      }
    ]
  },
  zahranici: {
    title: 'Zahraničí',
    slug: topicSlug.zahranici,
    description: `Jsou Vašimi obchodními partnery zahraniční společnosti nebo dokonce firmy v zemích mimo EU? Pak vás bude trápit DPH a v některých případech také clo. Neváhejte se na nás obrátit, pomůžeme také se zpracováním dat pro Intrastat – od registrace až po samotné reporty.`,
    links: [
      {
        name: 'Intrastat',
        slug: 'intrastat/',
        description:
          'Intrastat je statistický systém, který eviduje pohyb zboží mezi členskými státy EU. Hlášení musí podávat osoby identifikované k dani, které překročily stanovené limity pro odeslání či příjem zboží do/ze zahraničí.'
      },
      {
        name: 'Prime Global',
        slug: 'prime-global/',
        description:
          ' Prime Global patří do TOP 5 organizací sdružujících nezávislé účetní firmy po celém světě a v EKP Advisory jsme hrdým členem. Společnost dokonce vyzdvihl časopis Forbes jako jednu z nejvlivnějších amerických firem.'
      }
    ]
  },
  firemniPoradenstvi: {
    title: 'Firemní poradenství',
    slug: topicSlug.firemniPoradenstvi,
    description: `Pouštíte se do přeměny společnosti, likvidace či restrukturalizace? Řešte změny včas s našimi profesionály. Budete tak mít jistotu, že vše proběhne účetně správně, v mezích zákona a s minimálními daňovými dopady. Dále se specializujeme na nasazení ERP systémů a vyhledávání i testování kandidátů na ekonomické pozice.`,
    links: [
      {
        name: 'Likvidace společnosti',
        slug: 'likvidace-spolecnosti/',
        description:
          'Výsledkem likvidace společnosti je vyjasnění majetkových poměrů firmy a vypořádání závazků. Jedná se o problematiku, se kterou běžní účetní zpravidla nemají tolik zkušeností. Rádi Vám pomůžeme.'
      },
      {
        name: 'Přeměny',
        slug: 'premeny/',
        description:
          'Plánujete fúzi, akvizici, rozdělení nebo jinou přeměnu společnosti? Pak se připravte na proces, který může být nákladný a náročný i z hlediska času a administrativy. S přeměnami máme mnoho zkušeností a rádi pomůžeme nalézt optimální řešení i pro Vás.'
      },
      {
        name: 'Restrukturalizace',
        slug: 'restrukturalizace/',
        description:
          'Vždy zohledňujeme konkrétní potřeby firmy, cíle restrukturalizace a nabízíme řešení na míru dané společnosti či skupiny tak, aby byla restrukturalizace snadno zrealizovatelná, funkční a dávala smysl také z daňového a účetního hlediska.'
      },
      {
        name: 'Vzdělávání',
        slug: 'vzdelani-ucetnictvi-dane/',
        description:
          'Připravujeme praktická školení firmám na míru. Tak aby Vaši zaměstnanci získané znalosti využili již zítra při své práci. Neváhejte se na nás obrátit s Vašimi specifickými požadavky a dopřejte svým zaměstnancům nebo sobě školení, které skutečně využijete.'
      },
      {
        name: 'Due-diligence',
        slug: 'due-diligence/',
        description:
          'Prodej či nákup společnosti je složitý proces, který obnáší mnoho potenciálních skrytých problémů. Náš tým zkušených odborníků se postará, aby Vás žádný účetní nebo daňový problém nepřekvapil.'
      }
    ]
  },
  ucetnictvi: {
    title: 'Účetnictví',
    slug: topicSlug.ucetnictvi,
    description:
      'Zatěžuje vás vedení účetnictví nebo si nejste jistí správností? Ulevíme vám a pomůžeme nastavit kompletní účetní procesy. Navrhneme a zaimplementujeme reporting a/nebo controlling podle Vašich představ, který při správném používání povede k rozklíčování nákladů, zakázek, projektů atd.. Dáme Vám tak do ruky skvělý nástroj pro ekonomické řízení Vaší firmy.',
    links: [
      {
        name: 'Vedení účetnictví',
        slug: 'vedeni-ucetnictvi/',
        description:
          'Svěřte účetnictví do rukou profesionálů. Nabízíme vedení jak finančního, tak například i mzdového účetnictví a pomůžeme také formou konzultací.'
      },
      {
        name: 'Reporting',
        slug: 'reporting/',
        description:
          'Poskytujeme také kompletní účetní a daňové poradenství a na základě reportingu můžeme navrhnout další kroky vedoucí ke zlepšení finančního stavu firmy a asistovat při jejich realizaci.'
      },
      {
        name: 'Controlling',
        slug: 'controlling/',
        description:
          'Controlling patří mezi procesy, které je výhodnější outsourcovat, při interním zpracování totiž hrozí ztráta nadhledu. My nabízíme nezávislý pohled, zkušené daňové poradce a výstupy, které Vaší společnosti skutečně pomůžou.'
      },
      {
        name: 'Nastavení procesů',
        slug: 'nastaveni-procesu/',
        description:
          'Funguje Vaše firma skutečně tak efektivně, jak by měla? Pomůžeme při nastavování účetních procesů tak, aby zaměstnanci zbytečně neztráceli čas a měli jasno ve svých kompetencích.'
      }
    ]
  },
  znaleckePosudky: {
    title: 'Znalecké posudky',
    slug: topicSlug.znaleckePosudky,
    description:
      'Řešíte civilní spor či dokonce trestní kauzu a potřebujete expertní posouzení v oblasti daní či účetnictví? Nechte si vypracovat znalecký posudek. Jedná se o objektivní a nezávislé hodnocení, které můžete použít jako důkazní prostředek. Mnohdy se totiž stává, že zúčastněné strany sporu mají na problematiku odlišný náhled, a právě vyjádření znalce pomůže posunout kauzu dále.'
  },
  esg: {
    title: 'ESG',
    slug: topicSlug.esg,
    description:
      'Potřebujete zajistit transparentní ESG reporting, vytvořit strategii udržitelnosti či zavést udržitelné postupy do vašeho podnikání? Obraťte se na nás – nabízíme reporting dle CSRD i na míru, tvorbu ESG strategie, školení, benchmarking a podporu při získání certifikací (např. Ecovadis). K dispozici je také vzdálený ESG manažer pro řešení urgentních i běžných požadavků.'
  }
}
export default Index
